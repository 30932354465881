/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import Image from "../components/Image";
import {graphql} from 'gatsby'
import Layout from "../components/layout"
import "./relaquacenter.css";

import {Card, Grid} from "@material-ui/core";
import Slider from "react-slick";
import MultiImageSlider from "../components/MultiImageSlider";
import CardContent from "@material-ui/core/CardContent";
import SEO from "../components/SEO";
import Map from '../components/MyMapComponent'
import Contact from "../components/Contact";
import Banner from "../components/Banner";
import {OutboundLink} from "gatsby-plugin-google-analytics"

const sliderSettings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    fade: true
};


export default class RelaquaCenter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            markers: [{
                title: this.props.data.wordpressWpRelaquaCenter.acf.maps_marker_title,
                key: this.props.data.wordpressWpRelaquaCenter.acf.maps_marker_title,
                markerLink: this.props.data.wordpressWpRelaquaCenter.acf.maps_marker_link,
                description: this.props.data.wordpressWpRelaquaCenter.acf.maps_marker_description,
                lat: parseFloat(this.props.data.wordpressWpRelaquaCenter.acf.maps.lat),
                lng: parseFloat(this.props.data.wordpressWpRelaquaCenter.acf.maps.lng)
            }]
        };
    }

    render() {
        return <Layout id="trainer">
            <SEO title={this.props.data.wordpressWpRelaquaCenter.yoast_meta.yoast_wpseo_title}
                 description={this.props.data.wordpressWpRelaquaCenter.yoast_meta.yoast_wpseo_metadesc}
            />
            <div className="slider">
                <Slider {...sliderSettings}>
                    {
                        this.props.data.wordpressWpRelaquaCenter.acf.slider_relaqua_center.map((slide) => {
                            return (
                                slide.image && <Banner key={<slide className="title" />}
                                                       customStyles={{marginTop: 0}}
                                                       title={slide.title}
                                                       linktext={slide.link_text}
                                                       link={slide.link}
                                                       description={slide.description}
                                                       image={slide.image}
                                                       externlink={true}
                                />
                            );
                        })
                    }
                </Slider>
            </div>

            <Grid container className='trainer-intro-row' type="flex" justify="start">
                <Grid item lg={6} md={12} xs={12}>
                    <div className='trainer-intro-image'>
                        <Image image={this.props.data.wordpressWpRelaquaCenter.acf.trainer_details.image} />
                    </div>
                </Grid>
                <Grid item lg={5} md={12} xs={12} className='trainer-intro-content'>
                    <h2 className='title-big'>{this.props.data.wordpressWpRelaquaCenter.acf.trainer_details.title}</h2>
                    <div dangerouslySetInnerHTML={{__html: this.props.data.wordpressWpRelaquaCenter.acf.trainer_details.longdescription}}></div>
                </Grid>
            </Grid>

            <Contact title={this.props.data.wordpressWpRelaquaCenter.acf.contact.title}
                     image={this.props.data.wordpressWpRelaquaCenter.acf.contact.image}
                     description={this.props.data.wordpressWpRelaquaCenter.acf.contact.description} />

            <Map markers={this.state.markers}/>
            <br />
            <br />

            {
                this.props.data.wordpressWpRelaquaCenter.acf.video &&
                this.props.data.wordpressWpRelaquaCenter.acf.video.file &&
                <div className="video-wrapper" style={{margin: '0 auto'}}>
                    <video controls className="video" poster={this.props.data.wordpressWpRelaquaCenter.acf.video.thumbnail && this.props.data.wordpressWpRelaquaCenter.acf.video.thumbnail.src}>
                        <source src={this.props.data.wordpressWpRelaquaCenter.acf.video.file.source_url} type="video/mp4" />
                    </video>
                </div>
            }

            <MultiImageSlider nodes={this.props.data.wordpressWpRelaquaCenter.acf.gallery} />

            <div className='dark-hero-content'>
                <h2 className='title-big'>Kurse</h2>
            </div>

            <Grid container className="becometrainer-content-row" type="flex" justify="start">
                {this.props.data.wordpressWpRelaquaCenter.acf.courses_relaqua_center &&
                <Grid container>
                    {
                        this.props.data.wordpressWpRelaquaCenter.acf.courses_relaqua_center.map((card, i)=> {
                            return <Grid item lg={4} xs={12} style={{marginBottom: '2em'}} key={`card_${i}`} spacing={2}>
                                <OutboundLink href={card.link}>
                                    <Card className="card">
                                        {
                                            card && card.image && card.image.localFile &&
                                            <Image image={card.image}/>
                                        }
                                        <CardContent style={{padding: '2em'}}>
                                            <h3 className='title-big'>
                                                {card.title}
                                            </h3>
                                            <div>
                                                {card.content}
                                            </div>
                                            <div style={{marginTop: '1em'}}>
                                                <strong>Dauer: </strong>
                                                <span>{card.duration}</span>
                                            </div>
                                            <div><strong>Ort: </strong> {card.location}</div>
                                            {card.price &&
                                            <div><strong>Preis: </strong> {card.price}</div>}
                                        </CardContent>
                                    </Card>
                                </OutboundLink>
                            </Grid>
                        })
                    }
                </Grid>
                }
            </Grid>

            <MultiImageSlider nodes={this.props.data.wordpressWpRelaquaCenter.acf.impressions}/>

            <div className='dark-hero-content'>
                <h2 className='title-big'>Weitere Angebote</h2>
            </div>

            {this.props.data.wordpressWpRelaquaCenter.acf.cards_relaqua_center &&
            <Grid container  className="becometrainer-content-row" type="flex" justify="start" spacing={2}>
                {this.props.data.wordpressWpRelaquaCenter.acf.cards_relaqua_center.map((card, i) => {
                    return <Grid item lg={4} xs={12} style={{marginBottom: '2em'}} key={`card_${i}`} spacing={2}>
                        <OutboundLink href={card.link} target="_blank">
                            <Card className="card">
                                {
                                    card && card.image && card.image.localFile &&
                                    <Image image={card.image}/>
                                }
                                <CardContent style={{padding: '2em'}}>
                                    <h3 className='title-big'>
                                        {card.title}
                                    </h3>
                                    {card.description}
                                </CardContent>
                            </Card>
                        </OutboundLink>
                    </Grid>
                })}
            </Grid>
            }
        </Layout>
    }
}

export const pageQuery = graphql`
    query($id: String!) {
        wordpressWpRelaquaCenter(id: { eq: $id }) {
            title
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
            acf {
                courses_relaqua_center {
                    title
                    content
                    link
                    location
                    duration
                    price
                    image {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                video {
                    file {
                        source_url
                    }
                    thumbnail {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                maps {
                    lat
                    lng
                }
                maps_marker_title
                maps_marker_description
                maps_marker_link
                contact {
                    title
                    description
                    image {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                trainer_details {
                    title
                    shortdescription
                    longdescription
                    image {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                trainer_contact
                slider_relaqua_center {
                    title
                    description
                    link
                    link_text
                    image {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                gallery {
                    alt_text
                    title
                    description
                    localFile {
                        childImageSharp {
                            id
                            fluid {
                                base64
                                tracedSVG
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                originalName
                            }
                        }
                    }
                }
                impressions {
                    alt_text
                    title
                    description
                    localFile {
                        childImageSharp {
                            id
                            fluid {
                                base64
                                tracedSVG
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                originalName
                            }
                        }
                    }
                }

                cards_relaqua_center {
                    title
                    description
                    link
                    image {
                        alt_text
                        title
                        description
                        localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }

            }
        }
    }
`;


