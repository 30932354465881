import React from 'react'
import {Grid} from "@material-ui/core";
import './contact.css';
import Image from "./Image";

export default function Contact({title, image, description}) {
    return (
        <Grid container type="flex" justify="center" className='contact-field'>
            {image && image.localFile &&
            <Grid item lg={2} md={3} sm={4} xs={12}>
                <Image image={image} />
            </Grid>
            }
            <Grid item lg={12} md={12} xs={12} style={{textAlign: 'center'}}>
                <h3 className='title-big'>{title}</h3>
                <div dangerouslySetInnerHTML={{__html: description}}></div>
            </Grid>
        </Grid>
    )
};